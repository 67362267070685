import {
  CAMPAIGN_METRICS,
  SET_FRAC_SNAPSHOT,
  LIFT_STUDY,
  VISITS_DATA,
  FRACTIONAL_DATA,
  FREQUENCY_DATA,
  COHORT_ANALYSIS,
  DEMOGRAPHIC_DATA,
  DEVICE_METRICS_DATA,
  DRIVEN_VISITS_DATA,
  TOP_SEGMENTS_DATA,
  TOP_OOH_SEGMENTS_DATA,
  OOH_HOME_WORK_LOCATIONS_DATA,
  ATTRIBUTED_HOME_WORK_LOCATIONS_DATA,
  TOP_PLACES,
  LEADTIME_CONVERSION,
  DWELL_TIME,
  VISIT_SHARE_ANALYSIS,
  TOP_BILLBOARDS,
  DISTANCE_DATA,
  CAMPAIGN_FILTERS,
  AUDIENCE_FILTERS,
  OOH_AUDIENCE_FILTERS,
  PLACES_FILTERS,
  CLEAR_REPORT,
  PROFILE_COHORT_ANALYSIS,
  SEGMENT_COHORT_ANALYSIS,
  OOH_DEMOGRAPHIC_DATA,
  DSP_REPORTED_CAMPAIGN_DELIVERY_DATA,
  DSP_REPORTED_IMPRESSIONS_DATA,
  OBSERVED_REACH_FREQUENCY_DATA,
  ATTRIBUTED_AGE_GENDER_DATA,
  BILLBOARD_REACH_FREQUENCY_DATA,
} from './reportConstants';

let initialState = {
  attributedHomeWorkLocations: {},
};

export default function (state, action) {
  state = state || initialState;
  switch (action.type) {
    case CAMPAIGN_METRICS:
      return { ...state, metrics: action.data };

    case SET_FRAC_SNAPSHOT:
      return { ...state, [action.data.frac]: action.data.data };

    case LIFT_STUDY:
      return { ...state, visitLift: action.data };

    case VISITS_DATA:
      return { ...state, visitsData: action.data };

    case FRACTIONAL_DATA:
      return { ...state, [action.data.fracType]: action.data.data };

    case FREQUENCY_DATA:
      return { ...state, frequency: action.data };

    case COHORT_ANALYSIS:
      return { ...state, cohortAnalysis: action.data };

    case DEMOGRAPHIC_DATA:
      return { ...state, demographic: action.data };

    case OOH_DEMOGRAPHIC_DATA:
      return { ...state, ooh_demographic: action.data };

    case DEVICE_METRICS_DATA:
      return { ...state, deviceMetrics: action.data };

    case DRIVEN_VISITS_DATA:
      return { ...state, drivenVisits: action.data };

    case TOP_SEGMENTS_DATA:
      return { ...state, topSegments: action.data };

    case TOP_OOH_SEGMENTS_DATA:
      return { ...state, oohTopSegments: action.data };

    case OOH_HOME_WORK_LOCATIONS_DATA:
      return { ...state, oohHomeWorkLocations: action.data };

    case ATTRIBUTED_HOME_WORK_LOCATIONS_DATA:
      return { ...state, attributedHomeWorkLocations: action.data };

    // Add Experian data code changes
    case ATTRIBUTED_AGE_GENDER_DATA:
      return { ...state, attributedAgeGender: action.data };

    case TOP_PLACES:
      return { ...state, topPlaces: action.data };

    case LEADTIME_CONVERSION:
      return { ...state, leadtime: action.data };

    case DWELL_TIME:
      return { ...state, dwellTime: action.data };

    case VISIT_SHARE_ANALYSIS:
      return { ...state, visitShare: action.data };

    case TOP_BILLBOARDS:
      return { ...state, billboards: action.data };

    case DISTANCE_DATA:
      return { ...state, distance: action.data };

    case CAMPAIGN_FILTERS:
      return { ...state, campaignFilters: action.data };

    case AUDIENCE_FILTERS:
      return { ...state, audienceFilters: action.data };
    case OOH_AUDIENCE_FILTERS:
      return { ...state, oohAudienceFilters: action.data };

    case PLACES_FILTERS:
      return { ...state, placesFilters: action.data };

    case PROFILE_COHORT_ANALYSIS:
      return { ...state, profileCohortAnalysis: action.data };

    case SEGMENT_COHORT_ANALYSIS:
      return { ...state, segmentCohortAnalysis: action.data };

    case DSP_REPORTED_CAMPAIGN_DELIVERY_DATA:
      return { ...state, dspReportedCampaignDeliveryData: action.data };

    case DSP_REPORTED_IMPRESSIONS_DATA:
      return { ...state, impressionsData: action.data };

    case OBSERVED_REACH_FREQUENCY_DATA:
      return { ...state, observedReachFrequencyData: action.data };
    case BILLBOARD_REACH_FREQUENCY_DATA:
      return { ...state, billboardReachFrequencyData: action.data };
    case CLEAR_REPORT:
      return {};

    default:
      return state;
  }
}
